// JsFromRoutes CacheKey 72abea9c2c89c574c233061df7a77c34
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: definePathHelper('post', '/admin/wysiwyg_content_templates/new_modal'),
  table: definePathHelper('post', '/admin/wysiwyg_content_templates/table'),
  listJson: definePathHelper('get', '/admin/wysiwyg_content_templates/list_json'),
  recordUsage: definePathHelper('post', '/admin/wysiwyg_content_templates/:id/record_usage'),
  list: definePathHelper('get', '/admin/wysiwyg_content_templates'),
  create: definePathHelper('post', '/admin/wysiwyg_content_templates'),
  edit: definePathHelper('get', '/admin/wysiwyg_content_templates/:id/edit'),
  get: definePathHelper('get', '/admin/wysiwyg_content_templates/:id'),
  update: definePathHelper('patch', '/admin/wysiwyg_content_templates/:id'),
  destroy: definePathHelper('delete', '/admin/wysiwyg_content_templates/:id'),
}
