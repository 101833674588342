// JsFromRoutes CacheKey dc8c6f33b6bca5b8d0c6a71d17932db5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  preview: definePathHelper('post', '/admin/mailings/:id/preview'),
  duplicate: definePathHelper('post', '/admin/mailings/:id/duplicate'),
  deliver: definePathHelper('post', '/admin/mailings/:id/deliver'),
  spamCheck: definePathHelper('get', '/admin/mailings/:id/spam_check'),
  searchRecipients: definePathHelper('post', '/admin/mailings/:id/search_recipients'),
  domainStats: definePathHelper('get', '/admin/mailings/:id/domain_stats'),
  list: definePathHelper('get', '/admin/mailings'),
  create: definePathHelper('post', '/admin/mailings'),
  new: definePathHelper('get', '/admin/mailings/new'),
  edit: definePathHelper('get', '/admin/mailings/:id/edit'),
  get: definePathHelper('get', '/admin/mailings/:id'),
  update: definePathHelper('patch', '/admin/mailings/:id'),
  destroy: definePathHelper('delete', '/admin/mailings/:id'),
}
